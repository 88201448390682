<template>
  <div class="md-layout">
    <div class="md-layout-item md-size-66 md-xsmall-size-80 mx-auto">
      <create-customer-form @on-submit="onCreateCustomerSubmit"></create-customer-form>
    </div>
  </div>
</template>
<script>
  import CreateCustomerForm from '../Forms/CreateCustomerForm.vue'
  import swal from 'sweetalert2'

  export default {
    components: {
      CreateCustomerForm
    },
    methods: {
      onCreateCustomerSubmit (model, isValid) {
        if (isValid) {
          var self = this
          var formData = new FormData();
          formData.append('name', model.name);
          if (model.image) formData.append('image', model.image);
          var xhr = new XMLHttpRequest()
          xhr.open('POST', self.$root.apiUrl + 'v1/customers')
          xhr.setRequestHeader('Authorization', localStorage.getItem('token'))
          xhr.onload = function () {
            if (xhr.status === 401) self.$router.push({name: 'Login'})
            var response = JSON.parse(xhr.responseText)
            if (xhr.status === 201) {
              self.notifyVue('top','right')
              self.$router.push({name: 'Customers'})
            } else {
              var message
              if (response.response && response.response[0] && response.response[0].message)
                message = response.response[0].message
              else
                message = 'Unknown error'
              self.showSweetAlertError(message)
            }
          }
          xhr.send(formData)
        }
      },
      showSweetAlertError (message) {
        swal({
          title: 'An error occured!',
          text: message,
          type: 'warning',
          showConfirmButton: false,
          showCancelButton: true,
          cancelButtonText: 'Try again',
          cancelButtonClass: 'md-button md-success',
          buttonsStyling: false
        })
      },
      notifyVue (verticalAlign, horizontalAlign) {
        this.$notify(
          {
            message: 'Customer created succesfully.',
            icon: 'person_add',
            horizontalAlign: horizontalAlign,
            verticalAlign: verticalAlign,
            type: 'success'
          }
        )
      }
    }
  }
</script>
<style>
</style>
